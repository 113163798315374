import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Col } from 'styled-bootstrap-grid';
import LazyLoad from 'react-lazyload';

// Components
import EventCard from '../events/eventCard';

const EventContainer = `
  margin-bottom: 36px;

  @media (max-width: 575px) {
    margin-bottom: 18px;
  }
`;

export const ListDrawingRetreats = ({ color, textColor }) => {
  const [products, setProducts] = useState([]);

  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          select_drawing_retreats {
            drawing_retreat {
              document {
                ... on PrismicEvent {
                  uid
                  prismicId
                  data {
                    title {
                      text
                    }
                    image {
                      alt
                      fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                        srcWebp
                        srcSetWebp
                      }
                    }
                    rawDate: date
                    rawEndDate: end_date
                    date(formatString: "ddd, DD MMM ‘YY")
                    end_date(formatString: "ddd, DD MMM ‘YY")
                    partner_event
                    eventbrite_link {
                      url
                    }
                    tutor {
                      url
                      document {
                        ... on PrismicTutors {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                    location {
                      url
                      document {
                        ... on PrismicLocation {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                    start_time
                    finish_time
                    full_price
                    discounted_price
                  }
                }
              }
            }
          }
        }
      }
      allPrismicEvent(
        limit: 2
        filter: { data: { drawing_retreat: { eq: "True" } } }
        sort: { order: ASC, fields: [data___date, data___start_time] }
      ) {
        edges {
          node {
            uid
            prismicId
            data {
              title {
                text
              }
              image {
                alt
                fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
                  srcWebp
                  srcSetWebp
                }
              }
              eventbrite_link {
                url
              }
              drawing_retreat
              partner_event
              rawDate: date
              rawEndDate: end_date
              date(formatString: "ddd, DD MMM ‘YY")
              end_date(formatString: "ddd, DD MMM ‘YY")
              tutor {
                url
                document {
                  ... on PrismicTutors {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              location {
                url
                document {
                  ... on PrismicLocation {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
              start_time
              finish_time
              full_price
              discounted_price
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const formattedProducts = data.prismicHomepage.data.events_list
      .filter(event => event !== null)
      .filter(event => event.event.document !== null)
      .map(product => {
        let sku = 'sku_' + product.event.document.prismicId;
        return (product.event.document = {
          ...product.event.document,
          ...product.data,
          activePrice: 'full_price',
          quantity: 1,
          sku: sku,
          productType: 'event',
        });
      });

    setProducts(formattedProducts);
  }, [data]);

  const setActivePrice = (price, sku) => {
    const updatedProducts = products.map(product => {
      if (product.sku === sku) {
        product.activePrice = price;
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const content = products.map((product, index) => {
    const formattedProductData = {
      name:
        product.activePrice === 'full_price'
          ? product.data.title.text
          : `${product.data.title.text} – Members Discount`,
      id:
        product.activePrice === 'full_price'
          ? product.sku
          : `${product.sku}_discounted`,
      price:
        product.activePrice === 'full_price'
          ? product.data.full_price * 100
          : product.data.discounted_price * 100,
      currency: 'GBP',
      image: product.data.image,
      uid: `/timetable/event/${product.uid}`,
      details: product.data,
      productType: `event`,
    };

    return (
      <Col col={6} md={6} xl={3} key={index} css={EventContainer}>
        <LazyLoad height={500}>
          <EventCard
            link={'/timetable/event/' + product.uid}
            details={product.data}
            date={product.data.date}
            endDate={product.data.end_date}
            cardType="event"
            color={color}
            textColor={textColor}
            sku={product.sku}
            onClick={(price, sku) => setActivePrice(price, sku)}
            activePrice={product.activePrice}
          />
        </LazyLoad>
      </Col>
    );
  });

  return <>{content}</>;
};
