import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';

import Gallery from '../components/images/gallery';
import OpeningText from '../components/text/openingText';
import TextBoxWithHeading from '../components/text/textboxWithHeading';

import { Line } from '../components/utility/line';
import ContentContainer from '../components/global/contentContainer';

import { ListEvents } from '../components/homepage/listevents';
import { ListDrawingRetreats } from '../components/homepage/listDrawingRetreats';

const HomepageContent = `
  background-color: #ffffdc;
`;

const ContentTitle = styled.h2`
  text-align: center;
  font-weight: normal;

  padding-bottom: 54px;

  @media (max-width: 767px) {
    font-size: 20px;

    padding-bottom: 40px;
  }
`;

const ViewFullTimetable = styled.h3`
  text-align: center;
  font-weight: normal;

  & > a {
    text-decoration: none;
  }
`;

const Theme = {
  highlightColor: '#ffe100',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#ffffdc',
};

const Index = ({ data, location }) => {
  return (
    <>
      <Helmet>
        <title>Draw Brighton</title>
        <meta name="title" content={data.prismicHomepage.data.title.text} />
        <meta
          name="description"
          content={data.prismicHomepage.data.text.text}
        />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk' + location.pathname}
        />
        <meta
          property="og:image"
          content={data.prismicHomepage.data.gallery[0].gallery_image.url}
        />
        <meta
          property=" og:image:secure_url"
          content={data.prismicHomepage.data.gallery[0].gallery_image.url}
        />
        <meta
          property="og:description"
          content={data.prismicHomepage.data.text.text}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta
          name="twitter:title"
          content={data.prismicHomepage.data.title.text}
        />
        <meta
          name="twitter:description"
          content={data.prismicHomepage.data.text.text}
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:image"
          content={data.prismicHomepage.data.gallery[0].gallery_image.url}
        />
      </Helmet>

      <Row css={ContainerTheme} justifyContent="center">
        <Gallery
          margin={'0 0 0 0'}
          images={data.prismicHomepage.data.gallery}
        />

        <Col col={12} lg={10}>
          <ContentContainer padding={'0 36px'}>
            <OpeningText text={data.prismicHomepage.data.text.html} />
          </ContentContainer>
        </Col>

        <Col col={12} css={HomepageContent}>
          <Row justifyContent="center">
            {/* <Col col={12}>
              <Line
                mobileMargin={'0 9px'}
                margin={'0 36px'}
                color={Theme.highlightColor}
              />
            </Col> */}

            <Col col={12}>
              <ContentContainer
                padding={'54px 36px 18px'}
                mobilePadding={'40px 9px 22px'}
              >
                <ContentTitle>Coming up soon…</ContentTitle>
                <Row>
                  <ListEvents
                    color={Theme.highlightColor}
                    textColor={Theme.textColor}
                  />
                </Row>
              </ContentContainer>

              <ContentContainer
                padding={'0 0 54px'}
                mobilePadding={'0 9px 40px'}
              >
                <ViewFullTimetable>
                  <Link to={'/timetable'}>View full Timetable →</Link>
                </ViewFullTimetable>
              </ContentContainer>
            </Col>

            <Gallery images={data.prismicHomepage.data.about_gallery} />

            <Col col={12}>
              <ContentContainer
                padding={'0 36px'}
                mobilePadding={'40px 9px 20px'}
              >
                <Row>
                  <TextBoxWithHeading
                    heading="ABOUT"
                    text={data.prismicHomepage.data.about_text.html}
                    link="/about"
                  />
                </Row>
              </ContentContainer>
            </Col>

            {/* <Col col={12}>
              <Line
                mobileMargin={'0 9px'}
                margin={'0 36px'}
                color={Theme.highlightColor}
              />
            </Col>

            <Col col={12}>
              <ContentContainer
                padding={'108px 36px 0'}
                mobilePadding={'40px 9px 0 9px'}
              >
                <ContentTitle>Try one of our drawing retreats…</ContentTitle>
                <Row>
                  <ListDrawingRetreats
                    color={Theme.highlightColor}
                    textColor={Theme.textColor}
                  />
                </Row>
              </ContentContainer>
            </Col>

            <Col col={12}>
              <ContentContainer
                padding={'54px 36px 0'}
                mobilePadding={'22px 9px 40px'}
              >
                <Row>
                  <TextBoxWithHeading
                    heading="TAKE A BREAK"
                    text={data.prismicHomepage.data.drawing_retreat_text.html}
                    link="/timetable"
                  />
                </Row>
              </ContentContainer>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  query HomepageQuery {
    prismicHomepage {
      data {
        title {
          text
        }
        text {
          html
          text
        }
        gallery {
          gallery_image {
            url
            copyright
            dimensions {
              width
              height
            }
          }
        }
        about_gallery {
          gallery_image {
            dimensions {
              width
              height
            }
            copyright
            url
          }
        }
        about_text {
          html
        }
        drawing_retreat_text {
          html
        }
      }
    }
  }
`;

export default Index;
